<template>
  <div></div>
</template>

<script>
import { Toast } from 'vant'
import { getCommSsoUserInfo } from '@/api/wx.js'
export default {
  data() {
    return {}
  },
  mounted() {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0
    })
    localStorage.setItem('url', window.V3url + '/weixinlocal/index.html')
    sessionStorage.setItem('url', window.V3url + '/weixinlocal/index.html')
    this.getUserInfo()
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      getCommSsoUserInfo({ guid: this.$route.query.guid }).then(res => {
        localStorage.setItem('userInfo', JSON.stringify(res.userinfo[0]))
        sessionStorage.setItem('userInfo', JSON.stringify(res.userinfo[0]))
        localStorage.setItem('v8_login_pwd', res.userinfo[0].pwd)
        localStorage.setItem('v8_login_username', res.userinfo[0].username)
        Toast.clear()
        this.$router.replace('/' + res.pathinfo[0].path)
      })
    }
  }
}
</script>

<style></style>
